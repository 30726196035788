@import '_config';
@import '_sprite';

/*
ヘッダー gHeader

Markup:
<header class="gHeader">
	<div class="gHeader_inner">
		<p class="menuNavBtn pc-hidden"><a href="javascript: void(0);"><span></span></a></p>
		<div class="gHeader_left">
			<h1 class="gHeader_logo">
				<a href="../index.html"><img src="./assets/images/global/header_logo.png" alt="marunouchi capital"></a>
				<!-- <span class="yuGothic fBold">製品サイト</span> -->
			</h1>
		</div>
		<div class="gHeader_right menuNav">
			<div class="menuNav_inner">
				<p class="menuNav_close pc-hidden"><a href="javascript: void(0);"><span><i>close</i></span></a></p>
				<div class="gHeader_subMenus">
					<ul class="gHeader_lang">
						<li class="gHeader_langItem"><a href="#">English</a></li>
						<li class="gHeader_langItem"><a href="#">Chinese</a></li>
					</ul>
					<p class="gHeader_shopBtn"><a href="#">公式ショップ</a></p>
				</div>
				<div class="gHeader_mainMenus">
					<nav class="gNav">
						<div class="gNav_inner">
							<ul class="gNav_list">
								<li class="gNav_item"><a href="#">企業情報</a></li>
								<li class="gNav_item"><a href="#">乳酸菌生産物質とは?</a></li>
								<li class="gNav_item"><a href="#">Sixteens</a></li>
								<li class="gNav_item"><a href="#">研究</a></li>
								<li class="gNav_item"><a href="#">お問い合わせ</a></li>
							</ul>
						</div>
					</nav><!-- gNav -->
				</div>
			</div>
		</div><!-- gHeader_right -->
	</div><!-- gHeader_inner -->
</header><!-- gHeader -->
============================================================*/
.gHeader {
	border-bottom: solid 1px #e5e5e5;
	&_inner {
		width: 1200px;
		margin: 0 auto;
		padding: 15px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&_left {
	}
	&_logo {
	}
	&_right {
	}
	&_subMenus {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	&_lang {
		display: flex;
		margin-top: 0;
		li + li {
			a {
				border-left: dotted 1px #b2b2b2;
			}
		}
	}
	&_langItem {
		display: block;
		a {
			font-size: 1.4rem;
			font-family: arial;
			line-height: 25px;
			padding: 0 20px;
		}
	}
	&_shopBtn {
		margin-top: 0;
		width: 160px;
		a {
			font-size: 1.2rem;
			line-height: 36px;
			color: #0066bb;
			font-weight: bold;
			text-align: center;
			display: block;
			border-right: 5px;
			background-color: #e0ecf7;
			i::before {
			    content: url(../images/global/shop_logo.png);
			    position: relative;
			    margin-right: 5px;
			    top: 5px;
			}
		}
	}
	&_mainMenus {
	}
}
.gNav {
	&_inner {
	}
	&_list {
		display: flex;
		margin-top: 15px;
	}
	&_item {
		display: block;
		a {
			padding: 0 35px;
			font-weight: bold;
		}
		&:last-child {
			a {
				padding-right: 0;
			}
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.gHeader {
		&_inner {
			width: 100%;
			padding: 10px 0;
			display: block;
		}
		&_left {
		}
		&_logo {
			text-align: center;
			img {
				width: 150px;
			}
		}
		&_right {
		}
		&_subMenus {
			display: block;
			text-align: center;
		}
		&_lang {
			justify-content: center;
		}
		&_langItem {
		}
		&_shopBtn {
			margin: 10px auto 0;
		}
		&_mainMenus {
		}
	}
	.menuNavBtn {
	}
	.pc {
		&-hidden {
		}
	}
	.menuNav {
		&_inner {
		}
		&_close {
		}
	}
	.gNav {
		&_inner {
		}
		&_list {
			margin-top: 20px;
			display: block;
			text-align: center;
		}
		&_item {
			a {
				border-top: dotted 1px #ddd;
				display: block;
				padding: 10px 0;
			}
			&:last-child {
				a {
					border-bottom: dotted 1px #ddd;
				}
			}
		}
	}
	.menuNav {
		position: fixed;
	    top: 0;
	    right: -100%;
	    width: 85%;
	    height: 100%;
	    z-index: 10000;
	    padding: 0;
	    background-color: #fff;
	    overflow-y: auto;
        -webkit-overflow-scrolling: touch;
		-webkit-transition: right .4s;
		transition: right .4s;
		border-top: none;
		border-bottom: none;
		&_inner {
			width: auto;
			padding: 60px 10px 30px;
		}
		&_close {
			position: absolute;
		    top: 13px;
		    right: 10px;
		    margin-top: 0;
			a {
				position: relative;
			    display: block;
			    margin: 0 auto;
			    width: 40px;
			    height: 38px;
			    text-align: center;
			    text-decoration: none;
			}
			span::before, span::after {
				position: absolute;
			    top: 50%;
			    left: 50%;
			    margin-top: -10px;
			    margin-left: -11px;
			    display: inline-block;
			    content: "";
			    width: 22px;
			    height: 2px;
			    background-color: #333;
			    text-rendering: auto;
			    -webkit-font-smoothing: antialiased;
			}
			span {
				&:before {
					transform: translateY(0) rotate(-45deg);
				}
				&:after {
					transform: translateY(0) rotate(45deg);
				}
				i {
					padding-top: 22px;
				    display: block;
				    text-align: center;
				    font-size: 12.8px;
				    font-style: normal;
				    color: #777;
				    text-decoration: none;
				}
			}
		}
		&_toggle {
			&:hover {
				border-bottom: none;
			}
		}
	}
	// ハンバーガーボタン
	.menuNavBtn {
		position: absolute;
		top: 10px;
		left: 10px;
		display: inline-block;
		margin: 0;
		vertical-align: middle;
		z-index: 100;
	}
	.menuNavBtn > a {
		position: relative;
		display: block;
		width: 40px;
		height: 40px;
		border-radius: 4px;
		background: #266fbf;
	}
	.menuNavBtn > a:hover {
		opacity: 1;
	}
	.menuNavBtn > a span,
	.menuNavBtn > a:before,
	.menuNavBtn > a:after {
		display: block;
		width: 18px;
		height: 1px;
		background: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: 0px 0 0 -9px;
		-webkit-transition: all .4s;
		transition: all .4s;
		z-index: 20000000;
	}
	.menuNavBtn > a:before, .menuNavBtn > a:after {
		content: '';
	}
	// 三本線の上と下の位置
	.menuNavBtn > a:before {
		-webkit-transform: translateY(-8px);
		-ms-transform: translateY(-8px);
		transform: translateY(-8px);
	}
	.menuNavBtn > a:after {
		-webkit-transform: translateY(8px);
		-ms-transform: translateY(8px);
		transform: translateY(8px);
	}
	.menuNavOverlay,
	.menuNavOverlay02 {
		background: #fff;
		opacity: 0;
		z-index: 500;
		-webkit-transition: all .4s;
		transition: all .4s;
	}
	.menuNavOverlay-isOpen,
	.menuNavOverlay02-isOpen {
		display: block;
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: .8;
	}
	// ボタンクリック後のメニュースライド
	.menuNav-isOpen {
		right: 0%;
	}
	.menuNav:-webkit-scrollbar {
		cursor: pointer;
		width: 10px;
	}
	.menuNav:-webkit-scrollbar-track {
		background: #E5E5E5;
		cursor: pointer;
	}
	.menuNav:-webkit-scrollbar-thumb {
		background: #000;
		cursor: pointer;
	}
}


/*
フッター

Markup:
<footer class="gFooter">
	<div class="gFooter_section">
		<p class="gFooter_logo"><img src="./assets/images/global/footer_logo.png" alt="marunouchi capital"></p>
		<p class="gFooter_policy"><a href="">プライバシーポリシー</a></p>
		<ul class="gFooter_links">
			<li class="gFooter_link gFooter_link-fb"><a href="#"><img src="./assets/images/global/fb_icon.png" alt="イメージ画像"></a></li>
			<li class="gFooter_link gFooter_link-yt"><a href="#"><img src="./assets/images/global/yt_icon.png" alt="イメージ画像"></a></li>
			<li class="gFooter_link gFooter_link-tw"><a href="#"><img src="./assets/images/global/tw_icon.png" alt="イメージ画像"></a></li>
			<li class="gFooter_link gFooter_link-ig"><a href="#"><img src="./assets/images/global/ig_icon.png" alt="イメージ画像"></a></li>
		</ul>
		<p class="gFooter_copyright">&copy;○○○○ All rights reserved.</p>
	</div><!-- gFooter_section -->
</footer><!-- gFooter -->
============================================================*/
.gFooter {
	border: solid 1px #e5e5e5;
	padding: 25px 0 20px;
	&_section {
	}
	&_logo {
		text-align: center;
		margin-top: 0;
	}
	&_policy {
		font-size: 1.3rem;
		text-align: center;
		margin-top: 15px;
	}
	&_links {
		margin-top: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		li + li {
			margin-left: 20px;
		}
	}
	&_link {
		display: block;
		a {
			display: block;
			img {
				vertical-align: middle;
			}
		}
		&-fb {
		}
		&-yt {
		}
		&-tw {
		}
		&-ig {
		}
	}
	&_copyright {
		font-size: 1.2rem;
		font-family: arial;
		text-align: center;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.gFooter {
		padding: 15px 0;
		&_logo {
			img {
				width: 150px;
			}
		}
		&_policy {
			margin-top: 10px;
		}
		&_links {
			margin-top: 10px;
		}
	}
}

/*
pageTop

Markup:
<p class="pageTop"><a href="#"><img src="./assets/images/global/pageTop.png" alt="ページトップへ"></a></p>
============================================================*/
.pageTop {
	position: fixed;
    margin: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
}
