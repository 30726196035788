@import '_config';
@import '_sprite';

/*============================================================
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
============================================================*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
	margin:0;
	padding:0;
	border:0;
	outline:0;
	font-size:100%;
	vertical-align:baseline;
	background:transparent;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
body {
	line-height:1;
}
article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
	display:block;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
blockquote, q {
	quotes:none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content:'';
	content:none;
}
a {
	margin:0;
	padding:0;
	font-size:100%;
	vertical-align:baseline;
	background:transparent;
}
/* change colours to suit your needs */
ins {
	text-decoration:none;
}
/* change colours to suit your needs */
mark {
}
del {
	text-decoration: line-through;
}
abbr[title], dfn[title] {
}
table {
	border-collapse:collapse;
	border-spacing:0;
}
/* change border colour to suit your needs */
hr {
	display:block;
	height:1px;
	border:0;
	border-top:1px solid #cccccc;
	margin:1em 0;
	padding:0;
}
input, select {
	vertical-align:middle;
}
// li {
// 	display: block;
// }
input[type="check"],
input[type="radio"] {
	margin: 0;
	vertical-align: middle;
	margin: 0 5px 0 0;
}

/* 再定義
-------------------------------------------------------------*/
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
body {
	// font-family: 'メイリオ',Meiryo, 'ヒラギノ角ゴ Pro','Hiragino Kaku Gothic Pro', Verdana,Arial,sans-serif,'ＭＳ Ｐゴシック';
	font-family:"Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
	font-size:14px;
	line-height:1.6;
	color:#333;
	white-space: normal;
	text-align:left;
	-webkit-text-size-adjust: 100%;
	overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
}
img {
	vertical-align:bottom;
}
hr { margin: 20px 0 0; }
p,ul,ol,table,dl,figure {
	margin-top:20px;
}
b,strong {
	font-weight: bold;
}
em {
	font-style: italic;
}
button {
	font-family: inherit;
}
input[type="text"],
input[type="password"],
select,
textarea {
	width: 100%;
	font-family: inherit;
}
table {
	width: 100%;
}
th,
td {
	text-align: left;
}

/* 追加共通
-------------------------------------------------------------*/
html {
	font-size: 62.5%;
} /* 10px */
body {
	font-size: 1.6rem;
} /* 16px */
a {
	text-decoration: none;
	color: #333333;
}
a:hover {
	opacity: 0.8;
	filter: alpha(opacity=80);
	-moz-opacity:0.8;
}
img {
    vertical-align: bottom;
    max-width: 100%;
    //max-height: 100%;
    //width: auto; ウィジウェグ対応用に追加
	height: auto; //ウィジウェグ対応用に追加
}
ul:not([class]),
ol {
	margin-left: 2em;
	& > :first-child {
		margin-top: 0;
	}
	li {
		display: list-item;
	}
}


// body{
// 	user-select:none;
// 	-webkit-user-select:none;
// 	-moz-user-select:none;
// 	-khtml-user-select:none;
// 	-webkit-user-drag:none;
// 	-khtml-user-drag:none;
// }
// img {
// 	pointer-events: none;
// }

