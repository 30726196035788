@import '_config';
@import '_sprite';


/*
レイアウト

Markup:
{$modifiers}

.btn-red - red

Styleguide 0.0.0
============================================================*/
.content {
	&_inner {
		width: 1200px;
		margin: 0 auto;
		padding-bottom: 70px;
		min-height: calc(100vh - 384px);
		&-hr {
			display: flex;
			justify-content: space-between;
		}
	}
}
.side {
	width: 260px;
	>:first-child {
	    margin-top: 0px;
	}
}
.main {
	width: 880px;
	>:first-child {
	    margin-top: 0px;
	}
}
.section_narrow {
	width: 960px;
	margin: 0 auto;
}

@media screen and (max-width: $breakpoint-sp){
	.content {
		&_inner {
			width: 100%;
			padding: 0 10px;
			margin: 0 auto 30px;
			min-height: auto;
			&-hr {
				display: block;
			}
		}
	}
	.side {
		width: 100%;
		margin-bottom: 40px;
	}
	.main {
		width: 100%;
	}
	.section_narrow {
		width: 100%;
	}
}

/*
スライダー再定義

Markup:
{$modifiers}

.btn-red - red

Styleguide 0.0.0
============================================================*/
.mainSlider .slick-prev {
    left: 20px !important;
	height: 93px;
	width: 47px;
	text-indent: -9999px;
    z-index: 5;
    &:before {
		content:' ' !important;
		display: block;
		background: url(../images/top/arrow-prev.png) no-repeat left top;
		text-indent: -9999px;
		width: 47px;
		height: 93px;
	}
}
.mainSlider .slick-next {
    right: 20px !important;
	height: 93px;
	width: 47px;
	text-indent: -9999px;
    z-index: 5;
    &:before {
		content:' ' !important;
		display: block;
		background: url(../images/top/arrow-next.png) no-repeat right top;
		text-indent: -9999px;
		width: 47px;
		height: 93px;
	}
}

/*
mainImage

Markup:
{$modifiers}

.btn-red - red

Styleguide 0.0.0
============================================================*/

.mainImage:after {
    content: '';
    display: block;
    width: 100%;
    height: 60px;
    background: linear-gradient(to bottom, rgba(255,255,255,0), #FFF);
    margin-top: -60px;
    position: relative;
    z-index: 3;
}

@media screen and (max-width: $breakpoint-sp){
	.mainImage:after {
		height: 30px;
		margin-top: -30px;
	}
}

/*
topContent

Markup:
<div class="topContent">
	<div class="topContent_inner">
		<ul class="topContent_links">
			<li class="topContent_link">
				<a href="#">
					<p class="topContent_linkImg"><img src="./assets/images/top/links_img01.jpg" alt="イメージ画像"></p>
					<p class="topContent_linkName"><i></i>企業情報</p>
				</a>
============================================================*/
.topContent {
	&_inner {
		width: 1200px;
		margin: 0 auto 70px;
	}
	&_links {
		margin: -180px 0 0 -26px;
		position: relative;
		z-index: 3;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	&_link {
		display: block;
		margin: 30px 0 0 26px;
		a {
			display: block;
		}
	}
	&_linkImg {
		margin-top: 0;
	}
	&_linkName {
		text-align: center;
		margin-top: 5px;
		font-weight: bold;
		font-size: 1.8rem;
		i::before {
			@include fa();
		    content: '\f105';
		    background-color: #0066bb;
		    border-radius: 50%;
		    color: #fff;
		    width: 16px;
		    position: relative;
		    top: -1px;
		    line-height: 16px;
		    font-size: 1.4rem;
		    display: inline-block;
		    margin-right: 5px;
		}
	}
}


@media screen and (max-width: $breakpoint-sp){
	.topContent {
		&_inner {
			width: 100%;
			margin: 0 0 30px;
			padding: 0 10px;
		}
		&_links {
			margin: 0;
			display: block;
		}
		&_link {
			width: 280px;
			margin: 20px auto;
		}
	}
}
/*
トップニュース topNews

Markup:
<div class="topNews">
	<div class="topNews_inner">
		<p class="topNews_title">お知らせ</p>
		<ul class="topNews_list">
			<li class="topNews_item">
				<a href="#">
					<p class="topNews_setDate">2018.12.30</p>
					<p class="topNews_setLavel topNews_setLavel-blue">メディア紹介</p>
					<p class="topNews_setText">弊社村田が、文化放送のラジオプログラム「マスターズインタビュー」に出演いたしました。</p>
				</a>
			</li>
============================================================*/
.topNews {
	background: url(../images/top/topNews_bg.png) center top no-repeat;
    background-size: cover;
    padding: 55px 0;
	&_inner {
		width: 1200px;
		margin: 0 auto;
		padding: 40px 50px;
		background-color: rgba(255,255,255,0.5);
	}
	&_title {
		text-align: center;
		font-size: 2.2rem;
		font-weight: bold;
		margin: 0 0 25px;
	}
	&_list {
	}
	&_item {
		display: block;
		position: relative;
		a {
			display: flex;
			font-size: 1.5rem;
			padding: 15px 20px;
			align-items: flex-start;
			border-top: solid 1px #dddddd;
		}
		&:first-child {
			a {
				border-top: none;
			}
		}
	}
	&_setDate {
		font-size: 1.4rem;
		margin: 0 60px 0 0;
	}
	&_setLavel {
		margin-right: 20px;
		font-size: 1.1rem;
		width: 100px;
		margin-top: 0;
		text-align: center;
		color: #fff;
		line-height: 22px;
		&-blue {
			background-color: #0066bb;
		}
		&-light {
			background-color: #dbeaf7;
			color: #0066bb;
		}
		&-white {
			background: #fff;
			color: #0066bb;
			line-height: 20px;
			border: solid 1px #7cb1dd;
		}
	}
	&_setList {
		position: absolute;
		right: 20px;
		margin-top: 0;
		display: flex;
		li + li {
			margin-left: 10px;
		}
	}
	&_setListItem {
		display: block;
		color: #f15b28;
    	border: solid 1px #f15b28;
    	font-size: 1.1rem;
    	width: 120px;
    	line-height: 22px;
    	text-align: center;
		&-special {
		}
		&-carSim {
		}
	}
	&_setText {
		margin-top: 0;
		width: 800px;
	}
	&_viewAll {
		width: 280px;
		margin: 30px auto 0;
		a {
			text-align: center;
			display: block;
			color: #06b;
			font-size: 1.4rem;
			line-height: 38px;
			border: solid 1px #7cb1dd;
			&:before {
			    @include fa();
			    content: '\f105';
			    margin-right: 5px;
			}
		}
	}
}
.topNews {
	&_Tab {
		display: flex;
		align-items: center;
		margin-top: 45px;
	}
	&_TabItem {
		display: block;
		width: 25%;
		text-align: center;
		font-size: 1.5rem;
		color: #888888;
		line-height: 45px;
		cursor: pointer;
		border-bottom: 1px solid #cccccc;
	}
	&_body {
		margin-top: -1px;
	}
	&_bodyItem {
    	display: block;
	}
}
.topNews_TabItem.select {
    border-top: 1px solid #cccccc;
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid rgba(255,255,255,.5);
    color: #333;
    font-weight: bold;
    display: block;
    position: relative;
    z-index: 2;
}
.topNews_bodyItem.hide {
	display: none;
}

@media screen and (max-width: $breakpoint-sp){
	.topNews {
		padding: 20px 10px;
		&_inner {
			width: 100%;
			padding: 30px 15px;
		}
		&_title {
			margin: 0;
		}
		&_Tab {
			margin-top: 15px;
			display: block;
			border: 1px solid #ccc;
			li + li {
				margin-left: 0;
				border-top: 1px solid #ccc;
			}
		}
		&_TabItem {
			width:  100%;
			font-size: 1.4rem;
			border: none;
		}
		&_body {
		}
		&_bodyItem {
			padding: 0;
		}
		&_list {
			margin-top: 0;
			padding: 0 15px;
			border-left: 1px solid #ccc;
			border-right: 1px solid #ccc;
			border-bottom: 1px solid #ccc;
		}
		&_item {
			a {
				display: block;
			}
		}
		&_setDate {
			margin-right: 10px;
			display: inline-block;
		}
		&_setLavel {
			display: inline-block;
		}
		&_setText {
			margin-top: 10px;
			width: 100%;
		}
		&_viewAll {
			width: 200px;
		}
	}
	.topNews_TabItem.select {
		border: none;
		background-color: #ccc;
	}
}



/*
トップバナー topBnr

Markup:
<div class="topBnr">
	<div class="topBnr_inner">
		<ul class="topBnr_list">
			<li class="topBnr_item"><a href="#"><img src="./assets/images/top/banner01.jpg" alt="イメージ画像"></a></li>
			<li class="topBnr_item"><a href="#"><img src="./assets/images/top/banner02.jpg" alt="イメージ画像"></a></li>
			<li class="topBnr_item"><a href="#"><img src="./assets/images/top/banner03.jpg" alt="イメージ画像"></a></li>
		</ul>
	</div>
</div>
============================================================*/
.topBnr {
	&_inner {
		width: 1200px;
		margin: 50px auto;
	}
	&_list {
		display: flex;
		flex-wrap: wrap;
		margin: -20px 0 0 -60px;
	}
	&_item {
		display: block;
		margin: 20px 0 0 60px;
	}
}
@media screen and (max-width: $breakpoint-sp){
	.topBnr {
		&_inner {
			width: 100%;
			margin: 30px 0;
			padding: 0 10px;
		}
		&_list {
			display: block;
			margin: 0;
			text-align: center;
			li + li {
				margin-top: 20px;
			}
		}
		&_item {
			display: block;
			margin: 0;
		}
	}
}



/*
エディター部分

Markup:
{$modifiers}

.btn-red - red

Styleguide 0.0.0
============================================================*/
.editor {
	>:first-child {
	    margin-top: 0;
	}
	ul:not([class]) {
		li {
			font-size: 1.4rem;
			line-height: 24px;
		}
	}
	&_text {
		font-size: 1.5rem;
		margin-top: 30px;
		a {
			color: #0066bb;
		}
	}
	table {
		width: 100%;
	    border-width: 1px;
	    font-size: 1.4rem;
	    border-style: solid;
	    border-collapse: collapse;
	    th {
	    	font-weight: normal;
	    	background-color: #f8f8f8;
	    	padding: 16px 16px 13px;
	    	text-align: left;
		    vertical-align: top;
		    white-space: nowrap;
		    color: inherit;
		    border: 1px solid #e5e5e5;
	    }
	    td {
	    	padding: 16px 16px 13px;
	    	text-align: left;
		    vertical-align: top;
		    color: inherit;
		    border: 1px solid #e5e5e5;
	    }
	}
}
.headLine2 {
	font-size: 2.4rem;
	font-weight: bold;
	padding-bottom: 10px;
	margin: 35px 0 30px;
	border-bottom: solid 1px #dddddd;
}

.headLine {
	&_set {
		margin-top: 35px;
		h2 {
			margin-top: 10px;
		}
	}
	&_headDate {
		margin-top: 0;
		font-size: 1.5rem;
		vertical-align: top;
    	display: inline-block;
	}
	&_headLavel {
		margin-top: 0;
		font-size: 1.1rem;
	    min-width: 100px;
	    padding: 0 20px;
	    margin-left: 15px;
	    line-height: 22px;
	    text-align: center;
	    display: inline-block;
		&-blue {
			background-color: #06b;
    		color: #fff;
		}
	}
}
.common {
	&_btn {
		display: block;
		text-align: center;
		width: 280px;
		margin: 40px auto 0;
		line-height: 35px;
		border: solid 1px #85b3e1;
		color: #2a6ebe;
		font-size: 1.3rem;
		padding: 0 10px;
		&:before {
		    @include fa();
		    content: '\f105';
		    margin-right: 5px;
		}
	}
	&_readMore {
		display: block;
	    text-align: center;
	    width: 200px;
	    margin: 20px 0 0 auto;
	    line-height: 35px;
	    border: solid 1px #85b3e1;
	    color: #2a6ebe;
	    font-size: 13px;
	    font-size: 1.3rem;
	    padding: 0 10px;
	    &:before {
		    @include fa();
		    content: '\f105';
		    margin-right: 5px;
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.editor {
		table {
			border: 1px solid #e5e5e5;
		    th {
		    	padding: 10px 5px 7px;
		    	display: block;
		    	width: 100%;
		    }
		    td {
		    	padding: 10px 5px 7px;
		    	display: block;
		    }
		}
	}
	.headLine2 {
		font-size: 2rem;
	}
	.common {
		&_btn {
			width: 200px;
		}
		&_readMore {
			margin: 20px auto 0;
		}
	}
}


/*
会社概要 company

Markup:
{$modifiers}

.btn-red - red

Styleguide 0.0.0
============================================================*/
.company {
	&_map {
		margin-top: 40px;
	}
}

/*
ニュース news

Markup:
<div class="news">
	<ul class="news_list">
		<li class="news_item">
			<p class="news_setImg"><img src="../assets/images/content/news_img01.jpg" alt="ニュースイメージ画像"></p>
			<div class="news_setBody">
				<p class="news_setDate">2018.12.30</p>
				<p class="news_setLavel">ニュース</p>
				<p class="news_setText">2018年5月16日より3日間、ifia JAPAN2018（第23回国際食品素材/添加物展・会議）に出展いたします。</p>
			</div>
		</li>
============================================================*/
.news {
	&_list {
		li + li {
			border-top: dotted 1px #b2b2b2;
		}
	}
	&_item {
		display: block;
		a {
			display: block;
			padding: 20px 15px;
			display: flex;
		}
	}
	&_setImg {
		width: 160px;
		margin-top: 0;
	}
	&_setBody {
		margin-left: 30px;
		width: calc(100% - 190px);
	}
	&_setDate {
		font-size: 1.5rem;
		vertical-align: top;
		display: inline-block;
	}
	&_setLavel {
		font-size: 1.1rem;
		width: 100px;
		margin-left: 15px;
		line-height: 22px;
		text-align: center;
		display: inline-block;
		&-blue {
			background-color: #0066bb;
			color: #fff;
		}
		&-light {
			background-color: #e0ecf7;
			color: #0066bb;
		}
		&-white {
			border: solid 1px #7fb2dd;
			color: #0066bb;
			line-height: 20px;
		}
	}
	&_setText {
		font-size: 1.5rem;
	}
}


@media screen and (max-width: $breakpoint-sp){
	.news {
		&_list {
		}
		&_item {
			a {
				display: block;
				padding: 20px 0;
			}
		}
		&_setImg {
			width: 100%;
			text-align: center;
		}
		&_setBody {
			margin-left: 0;
			width: 100%;
		}
		&_setDate {
		}
		&_setLavel {
		}
		&_setText {
			margin-top: 10px;
		}
	}
}

/*
ブログ blog

Markup:
<div class="blogPager">
    <ul class="blogPager_list">
        <li class="blogPager_item blogPager_item-prev"><a href="#">前の記事へ</a></li>
        <li class="blogPager_item blogPager_item-next"><a href="#">次の記事へ</a></li>
    </ul>
</div>
============================================================*/
.blog {
	&_subTitle {
		font-size: 1.8rem;
	    font-weight: 700;
	    padding-bottom: 10px;
	    margin: 10px 0 30px;
	    border-bottom: solid 1px #ddd;
	}
}
.blogPager {
	margin: 40px 0;
	&_list {
		display: flex;
		justify-content: center;
		li + li {
			margin-left: 40px;
		}
	}
	&_item {
		display: block;
		a {
			color: #0066bb;
			font-size: 1.5rem;
		}
		&-prev {
			a {
				&:before {
				    @include fa();
				    content: '\f104';
				    margin-right: 10px;
				    font-size: 2.2rem;
				    position: relative;
				    top: 2px;
				}
			}
		}
		&-next {
			a {
				&:after {
				    @include fa();
				    content: '\f105';
				    margin-left: 10px;
				    font-size: 2.2rem;
				    position: relative;
				    top: 2px;
				}
			}
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.blogPager {
		margin: 25px 0;
		&_list {
			li + li {
				margin-left: 25px;
			}
		}
	}
}
