@import '_config';
@import '_sprite';

/*
もろもろ

Markup:
<ul class="headLine_head">
	<li class="headLine_headDate">2018.12.30</li>
	<li class="headLine_headLavel headLine_headLavel-blue">ニュース</li>
</ul>
============================================================*/
.bgFixed {
    position: fixed;
    width: 100%;
    height: 100%;
}

/*
サイドテンプレート

Markup:
<ul class="tree_list">
	<li class="tree_item">
		<div class="tree_head">
			<a href="./midList.html">Corporate</a>
		</div>
	</li>
	<li class="tree_item">
		<div class="tree_head">
			<a href="./midList.html">会社情報</a><span class="tree_toggle"></span>
		</div>
		<ul class="tree_childList">
			<li class="tree_childItem"><a href="#">社長メッセージ</a></li>
			<li class="tree_childItem"><a href="#">会社概要</a></li>
			<li class="tree_childItem"><a href="#">事業内容</a></li>
			<li class="tree_childItem"><a href="#">沿革</a></li>
			<li class="tree_childItem"><a href="#">グループ会社一覧</a></li>
		</ul>
	</li>
	<li class="tree_item">
		<div class="tree_head">
			<a href="./midList.html">採用情報</a>
		</div>
	</li>
</ul>
============================================================*/
.tempSide {
	margin-top: 30px;
	&_title {
		font-size: 1.5rem;
		font-weight: bold;
		padding: 15px;
		color: #0066bb;
		background-color: #e0ecf7;
	}
	&_body {
		border-left: solid 1px #e5e5e5;
		border-right: solid 1px #e5e5e5;
		border-bottom: solid 1px #e5e5e5;
		padding: 0 15px;
	}
}
.tree {
	&_list {
		margin-top: 0;
	}
	&_item {
		display: block;
		border-top: dotted 1px #b2b2b2;
		a {
			font-size: 1.4rem;
			display: block;
		}
		&:last-child {
			margin-bottom: 0;
		}
		&:first-child {
			border-top: none;
		}
	}
	&_head {
		position: relative;
		a {
			padding: 15px 25px;
		}
	}
	&_toggle {
	    position: absolute;
	    left: 3px;
	    top: 0;
	    bottom: 0;
	    margin: auto 0;
	    width: 17px;
	    height: 17px;
	    cursor: pointer;
	    z-index: 2;
	    &:before {
		    @include fa();
		    content: '\f107';
		    text-align: center;
		    display: block;
		    font-size: 1.5rem;
		    width: 15px;
	    	line-height: 15px;
	    	color: #898989;
		}
		&-isOpen {
			&:before {
			    content: '\f106';
	    		line-height: 15px;
			}
		}
	}
	&_childList {
		margin-top: 0;
		padding: 0 30px;
		display: none;
	}
	&_childItem {
		display: block;
		a {
			padding: 10px;
		}
	}
}
@media screen and (max-width: $breakpoint-sp){
	.tree {
		&_list {
		}
		&_childItem {
			a {
			}
		}
	}
}



/*
ぱんくず

Markup:
<nav class="pankuzu">
	<ul class="pankuzu_list">
		<li class="pankuzu_item">ホーム</li>
		<li class="pankuzu_item"><a href="#">検索結果一覧</a></li>
	</ul>
</nav>
============================================================*/
.pankuzu {
	margin: 25px auto 30px;
	width: 1200px;
	&_list {
		margin-top: 0;
    	display: flex;
    	li + li {
    		&:before {
			    @include fa();
			    content: '\f105';
			    color: #cccccc;
			    margin: 0 1em;
			}
    	}
	}
	&_item {
		display: block;
		font-size: 1.4rem;
		color: #878787;
		&-home {
			i:before {
			    content: url(../images/content/home_icon.png);
			    position: relative;
			    top: 1px;
			}
		}
		a {
			color: #878787;
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.pankuzu {
		overflow-x: scroll;
		width: 100%;
		margin-bottom: 10px;
		&_list {
			justify-content: flex-start;
			padding: 8px;
		}
		&_item {
			white-space: nowrap;
			&:last-child {
				padding-right: 8px;
			}
		}
		&::-webkit-scrollbar {
		    height: 5px;
		}
		&::-webkit-scrollbar-track {
		    background-color: #FFF;
		}
		&::-webkit-scrollbar-thumb {
		    background-color: rgba( 238, 238, 238, 1);
		}
	}
}

/*
サイトマップエリア

Markup:
{$modifiers}

.btn-red - red

Styleguide 0.0.0
============================================================*/
.layoutArea {
	padding: 0 20px!important;
    max-width: initial!important;
}
.section:first-child {
    margin-top: 0;
}
.siteList {
	&_list {
		letter-spacing: -.44em;
    	position: relative;
    	&:before {
		    position: absolute;
		    display: block;
		    top: 50%;
		    left: 0;
		    content: "";
		    min-width: 100%;
		    height: 5px;
		    margin-top: 5px;
		    background-color: #666;
		}
	}
	&_item {
		display: inline-block;
	    letter-spacing: normal;
	    position: relative;
	    vertical-align: middle;
	    margin: 20px 20px 0 0;
	    padding: 6px;
	    background-color: #fff;
	    border: 1px solid #ccc;
		&:first-child {
		    margin-left: 0;
		}
		a {
			position: relative;
		    display: block;
		    width: 140px;
		    height: 80px;
		    text-align: center;
		    font-size: 12px;
		    line-height: 1.33333;
		    text-decoration: none;
			&:before {
			    top: 5px;
			    left: 5px;
			    @include fa();
			    content: "\f00b";
			    z-index: 5;
			}
		}
		span {
			display: block;
		    position: relative;
		    top: 50%;
		    text-align: center;
		    transform: translateY(-40%);
		}
	}
}
.siteList_item > a:before, .siteList_item > a:after, .siteList_item > div:before, .siteList_item > div:after {
    position: absolute;
    display: inline-block;
    font-family: FontAwesome;
    font-size: 16px;
    line-height: 1;
    text-decoration: none;
}
.ok {
	a {
		background-color: #266fbf;
		color: #fff;
	}
}
.add {
	a {
		background-color: #f49c00;
		color: #fff;
	}
}
.ok > a:after, .ok > div:after {
    @include fa();
    bottom: 5px;
    right: 5px;
    content: "\f00c";
    color: #ff0;
}
.ng {
	a {
		background-color: #005982;
		color: #fff;
	}
}
.ng > a:after, .ng > div:after {
    @include fa();
    bottom: 5px;
    right: 5px;
    content: "\f071";
    color: #ff0;
}

@media screen and (max-width: $breakpoint-sp){
	.siteList_list {
	    border-top: 5px solid grey;
	    margin: 5% 0 0;
	    &:before {
	    	display: none;
	    }
	}
	.siteList_item {
	    margin: 3% 3% 0 0;
	    padding: 6px;
	    width: 46%;
	}
	.siteList_item>a, .siteList_item>div {
	    width: auto;
	}
	a, button {
	    transition: none;
	}
}

/*
ページャー

Markup:
<div class="pager">
    <ul class="pager_list">
        <li class="pager_item pager_item-prev"><a href="#">&lt;</a></li>
        <li class="pager_item"><a href="#">1</a></li>
        <li class="pager_item next_item-dot"><a href="#">&#8230;</a></li>
        <li class="pager_item"><a href="#">2</a></li>
        <li class="pager_item"><a href="#">3</a></li>
        <li class="pager_item pager_item-current"><span>4</span></li>
        <li class="pager_item"><a href="#">5</a></li>
        <li class="pager_item"><a href="#">6</a></li>
        <li class="pager_item next_item-dot"><a href="#">&#8230;</a></li>
        <li class="pager_item sp-hidden"><a href="#">15</a></li>
        <li class="pager_item pager_item-next"><a href="#">&gt;</a></li>
    </ul>
</div>
============================================================*/
.pager {
	margin-top: 50px;
	text-align: center;
    &_list {
        font-size: 0;
        margin: 20px 0 10px;
        a {
            font-size: 1.6rem;
        }
    }
    &_item {
        display: inline-block;
        width: 30px;
        line-height: 30px;
        margin: 0 3.5px;
        a {
            display: block;
            color: #888888;
        }
        &-prev {
        	width: 155px;
        	line-height: 32px;
        	background-color: #f1f1f1;
        	border-radius: 3px;
        }
        &-next {
            width: 155px;
        	line-height: 32px;
        	background-color: #f1f1f1;
        	border-radius: 3px;
        }
        &-current {
        	span {
        		color: #888888;
        		display: block;
			    font-size: 1.6rem;
        		border: solid 1px #c3c3c3;
        		border-radius: 3px;
        	}
        }
        &-dot {
        	span {
        		display: block;
        		color: #888888;
		    	font-size: 1.6rem;
        	}
        }
    }
}

@media screen and (max-width: $breakpoint-sp){
	.pager {
		margin-top: 30px;
		&_item {
			margin: 0;
			&-prev {
				width: 30px;
				line-height: 30px;
			}
			&-next {
				width: 30px;
				line-height: 30px;
			}
		}
	}
}